import React, { useContext, useState, useEffect } from "react";
import InterestedForm, { GenericForm, GenericFormUpload, TinyForm } from "../components/InterestedForm";
import PopUp from "../components/PopUp";
import { TransparentButton } from "../components/Button";
import { Row, Col } from "react-bootstrap";
import {
    useParams,
    Redirect,
    Link,
    useLocation,

} from "react-router-dom";


import { useNavigate } from "react-router-dom";
const left_arrow = (<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.88284 14.8189C7.17629 15.1123 7.65111 15.1154 7.94839 14.8259C8.25085 14.5312 8.25446 14.0463 7.95642 13.7472L2.74285 8.51506L17.2823 8.51506C17.7016 8.51506 18.0415 8.17514 18.0415 7.75582C18.0415 7.33651 17.7016 6.99658 17.2823 6.99658L2.74285 6.99658L7.97336 1.77091C8.26291 1.48162 8.26278 1.01226 7.97305 0.723143C7.66319 0.413933 7.16141 0.414196 6.85188 0.723732L0.419764 7.15584C0.0884037 7.4872 0.0884037 8.02444 0.419764 8.3558L6.88284 14.8189Z"
        fill="black" />
</svg>)

const close_button = (<svg width="35" height="35" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path style={{ filter: "drop-shadow( 0px 0px 1px rgba(0, 0, 0, .7))" }} d="M17.6563 18.2663L0.874023 1.48401M0.874023 18.2663L17.6563 1.48401L0.874023 18.2663Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
)


const FormContext = React.createContext();

export function useForms() {
    return useContext(FormContext);
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function FormProvider({ children }) {
    const [data, setData] = useState(null);
    const [extra, setExtra] = useState(null);
    const [action, setAction] = useState(null);
    const [show_form, setForm] = useState(false);
    const [show_gn, setGn] = useState(false);
    const [ad, setActualAd] = useState();
    const [show_ad, setAd] = useState(false);
    const [sent, setSent] = useState(false);
    const [show_cv, setCV] = useState(false);
    const [news, setNews] = useState(false);

    const navigate = useNavigate()
    const [back, setBack] = useState(false);
    const [top, setTop] = useState(null);
    const [caption, setCaption] = useState(null);
    const query = useQuery();

    async function showForm(d, e, a, f, t) {
        setData(d);
        setExtra(e);
        setAction({ callback: a });
        setBack(f ? true : false);
        setForm(true);
        setTop(t)
    }

    async function showGeneric(data) {
        setData(data);
        setGn(true)
    }
    async function showCV(data) {
        setCV(true);
    }

    async function showAd(d, e, a, ad, cap) {
        setData(d);
        setExtra(e);
        setAction({ callback: a });
        setActualAd(ad)
        setCaption(cap)
        setAd(true);
    }

    function handler(e) {
        if (action && action.callback) action.callback(e);
    }

    const value = {
        showForm,
        showAd,
        showGeneric,
        showCV
    }

    const home_button = (<div onClick={() => { navigate(window.location.pathname); setSent(false); setNews(false); }} style={{ textDecoration: "none", color: "white" }}> <div style={{ opacity: 1, marginTop: '1em' }} className="trans-btn-accent">
        <TransparentButton value="Close" style={{ borderStyle: "1px solid rgba(255, 255, 255, 0.5)", borderColor: "rgba(255, 255, 255, 0.7)", backgroundColor: "transparent", buttonColor: "btn-white" }} />
    </div></div>)

    useEffect(() => {
        if (query.get("submission") == "success") {
            setSent(true);
        }
        if (query.get("newsletter") == "success") {
            setNews(true)
        }
    });

    return (
        <FormContext.Provider value={value}>
            <PopUp close={() => { navigate(window.location.pathname); setSent(false) }} active={sent} message={(<div>We have received your request. Our representative will contact you shortly.<br></br>{home_button}</div>)} title="Request Submitted" />
            <PopUp close={() => { navigate(window.location.pathname); setNews(false) }} active={news} message={(<div>You are now subscribed to our newsletter<br></br>{home_button}</div>)} title="Newsletter Subscription" />

            <PopUp close={() => setForm(false || !back)} style={{ backgroundColor: 'transparent' }} active={show_form}
                message={(<div style={{ width: '500px', maxWidth: '100%' }}>
                    <InterestedForm top={top} close={() => setForm(false)} style={{ border: "1px solid rgba(0,0,0,0.8)" }} data={data} extra={extra} action={handler} />
                </div>)}

            />

            <PopUp close={() => setGn(false || !back)} style={{ backgroundColor: 'transparent' }} active={show_gn}
                message={(<div style={{ width: '500px', maxWidth: '100%' }}>
                    <GenericForm data={data} close={() => setGn(false)} style={{ border: "1px solid rgba(0,0,0,0.8)" }} />
                </div>)}

            />

            <PopUp close={() => setCV(false || !back)} style={{ backgroundColor: 'transparent' }} active={show_cv}
                message={(<div style={{ width: '500px', maxWidth: '100%' }}>
                    {/* <div style={{ cursor: 'pointer', marginTop: '-1em', marginBottom: "1em" }} onClick={() => setForm(false)}>
                        {left_arrow}
                    </div> */}
                    <GenericFormUpload data={data} close={() => setCV(false)} style={{ border: "1px solid rgba(0,0,0,0.8)" }} />
                </div>)}

            />

            <PopUp close={() => setAd(false)} style={{ backgroundColor: 'transparent' }} active={show_ad}
                message={(
                    <>
                        <div style={{ width: '760px', position: 'relative', cursor: 'pointer' }}>
                            <div onClick={() => setAd(false)} style={{ position: 'absolute', right: "20px", top: '20px' }}>{close_button}</div>
                            <img style={{ background: 'white', minHeight: '400px', width: '100%', objectFit: 'contain', objectPosition: 'center', backgroundColor: 'white' }} src={ad} />
                            <TinyForm caption={caption} data={data} extra={extra} action={handler} />

                        </div>


                    </>)}

            />


            {children}
        </FormContext.Provider>
    );
}