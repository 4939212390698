import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap';
import '../css/disclaimer.css';
import Fade from 'react-reveal/Fade';
import config from 'react-reveal/globals';
config({ ssrFadeout: true });

const close_button = (<svg width="17" height="17" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.6563 18.2663L0.874023 1.48401M0.874023 18.2663L17.6563 1.48401L0.874023 18.2663Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
</svg>
)

const arrow = (<svg width="20" height="20" viewBox="0 0 46 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className="phone-arrow-one" d="M1.82959 1.80597L30.8333 30.8096L1.82959 59.8132" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
    <path className="phone-arrow-two" d="M15.73 1.80597L44.7336 30.8096L15.73 59.8132" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
</svg>)

const contents = (<div style={{opacity: '0.7', fontSize: '0.8em'}}><a className="link-only" href="https://homekonnect.com">
    Homekonnect.com
</a> shall neither be responsible nor liable for any inaccuracy
    in the information provided here and therefore the customers are requested to independently validate the information
    from the respective developers before making their decisions related to properties displayed here. < a className="link-only" href="https://homekonnect.com" >
        Homekonnect.com
    </a >, its directors, employees,
    agents and other representatives shall not be liable for any action taken, cost / expenses / losses incurred, by you.</div>)

export default function Disclaimer() {
    const [state, setState] = useState(false);
    const [mob, setMob] = useState(false)
    
    useEffect(() => {
        const item = window.sessionStorage.getItem('disclaimer');
        if(!item) setState(true);
        window.sessionStorage.setItem('disclaimer', true);
    }, [])


    // if (!state) return (<></>);
    return (
        // <Fade bottom>
        <div data-state={state ? "true" : "false"} className="disclaimer-container gen-text">
            <Container >
                {/* Mobile */}
                <div className="d-block d-md-none">
                    <div onClick={() => setMob(!mob)} style={{ display: 'flex' }}>
                        <div style={{ transform: 'rotateZ(-90deg)', marginTop: '-2px', marginRight: "10px", display: mob ? "none" : "block" }}>
                            {arrow}
                        </div>

                        <div className="capital-text" style={{paddingLeft:'85px'}}>
                            Disclaimer
                        </div>
                        <div onClick={() => setState(false)} style={{ marginLeft: 'auto', display: !mob ? "none" : "block" }}>
                            {close_button}
                        </div>
                    </div>

                    <div data-state={mob ? "true" : "false"} className="disc-mob-contents">
                        <hr></hr>
                        {contents}
                    </div>

                </div>

                {/* Desktop */}
                <div style={{ position: "relative", marginLeft: '0px', marginRight: "70px" }} className="d-none d-md-block">

                    <div className="capital-text">
                        Disclaimer
                    </div>
                    <div onClick={() => setState(false)} style={{ position: 'absolute', right: 0, top: "-3px", cursor: 'pointer' }}>
                        {close_button}
                    </div>
                    <div style={{marginTop: '0.5em'}}>
                    {contents}
                    </div>
                </div>
            </Container>
        </div>
        // </Fade>
    )
}
