import React, { useState, useEffect, useRef } from 'react';
import "../css/alter.css";

export default function AlternatingIcon({ children, ttl = 2000 }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const counterRef = useRef(0);

    useEffect(() => {
        counterRef.current = currentIndex;

        const interval = setInterval(() => {
            window.requestAnimationFrame(() => {
                let nextIndex = counterRef.current + 1;
                if (nextIndex >= children.length) nextIndex = 0;
                setCurrentIndex(nextIndex);
                counterRef.current = nextIndex;
            });
        }, ttl);

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, [children.length, ttl]);

    return (
        <div style={{ position: 'relative' }}>
            {children.map((child, index) => (
                <div
                    key={index}
                    style={{ position: 'absolute' }}
                    className={index === currentIndex ? 'alter-show' : 'alter-hide'}
                >
                    {child}
                </div>
            ))}
        </div>
    );
}
