
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useForms } from '../contexts/FormContext'
import {
    whatsapp_icon,
    whatsapp_icon_black,
    phone_icon,
    phone_icon_black,
    msg_logo,
    msg_logo_black,
    enq_button,
    enq_button_black,
    close_button
} from './Icons';

import "../css/popup.css"
import "../css/getbutton.css"
import AlternatingIcon from './AlternatingIcon';

const whatsapp_message = "Hi, I would like to know more about your services.";


export default function GetButton() {
    const [state, setState] = useState(false);
    const [ms, setMs] = useState(false)
    const [up, setUp] = useState(false);
    const { showForm } = useForms();


    const [data, setData] = useState({
        phone_number: "+919025127127",
        whatsappbot: "+914443153537"
    });


    function enquire() {
        var a = decodeURIComponent(window.location.pathname);
        var b = a.split("/");
        if (!a.includes("/property/")) return window.open("/contact", "_self");
        if (b.length < 4) return window.open("/contact", "_self");
        const full_name = b[b.length - 1];
        const city = b[b.length - 2];
        const data = {
            full_name: full_name,
            location: window.locality,
            city: { data: { city_name: city } }
        }
        showForm(data, "clicked enq button from bottom");
    }


    function zoho_updates(e) {
        var zoho = document.getElementsByClassName("siq_bR");
        try {
            const mutation = e[0]
            if (zoho[0].style.getPropertyPriority("display") != "important" && zoho[0].style.display == "block") {
                var zoho_thing = zoho[0]
                if (window.innerWidth > 468) zoho_thing.style.setProperty('bottom', "140px", "important")
                if (state) {
                    zoho_thing.style.setProperty('display', "none", "")
                }
                else {
                    zoho_thing.style.setProperty('display', 'block', "important")
                }
            }
        }
        catch (err) {
        }
    }

    function turnOffZoho() {
        var elems = document.getElementsByClassName("siq_bR");
        var zoho_thing = elems[0];
        if (zoho_thing) zoho_thing.style.display = "none";
        else {
            setTimeout(() => { turnOffZoho() }, 300);
        }
    }

    useEffect(() => {
        var elems = document.getElementsByClassName("getbutton-icon");
        for (var i = 0; i < elems.length; i++) elems[i].style.display = "none";
        window.site_data.then(data => setData(data))
    }, [])

    function update_state() {
        var zoho_color = document.getElementsByClassName("zsiq_flt_rel");
        try {
            zoho_color[0].style.setProperty('background-color', "var(--accent)", 'important');
        }
        catch (err) {
        }
        
        var zoho = document.getElementsByClassName("siq_bR");

        zoho[0]?.style.setProperty('bottom', (window.innerWidth <= 468) ? "100px" : "140px", "important")
        //console.log('It is an error3');
        try {
            var observer = new MutationObserver(zoho_updates);
            observer.observe(zoho[0], {
                attributes: true,
                attributeFilter: ["style"]
            });
        }
        catch (err) {
            console.error('Error in zoho_updates:', err);
        }

        var elems = document.getElementsByClassName("siq_bR");
        try {
            var zoho_thing = elems[0];
            if (state) {
                zoho_thing.style.setProperty('display', "none", "")
            }
            else {
                zoho_thing.style.setProperty('display', 'block', "important")
            }
        }
        catch (err) {
        }

        elems = document.getElementsByClassName("getbutton-icon");
        for (var i = 0; i < elems.length; i++) {
            elems[i].style.display = state ? "none" : "block";
        }

        elems = document.getElementById("help-msg");
        elems.style.display = !state ? "none" : "block";
        setState(!state)
    }

    return (<>
        <div className="d-none d-sm-block">
            <div id="help-msg" className="getbutton-help" >
                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ margin: 'auto' }}> Click here to get help </div>
                </div>
            </div>
            <div className="getbutton-wrap">
                <div onClick={update_state} className={"main-button" + (state ? "" : " attention-button")} id ="KamalTest">
                    {(state) ? close_button :
                        (
                            <AlternatingIcon ttl={2000}>
                                {msg_logo}
                                {phone_icon}
                                {whatsapp_icon}
                                {enq_button}
                            </AlternatingIcon>
                        )
                    }
                </div>

                <a className="getbutton-icon getbutton-phone-icon-home"
                    target="_blank" href={"tel://" + data.phone_number}
                    style={{ 'margin-left': '0px' }}
                >
                    <div className="getbutton-title" >
                        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                            <div style={{ margin: 'auto' }}> Phone </div>
                        </div>
                    </div>
                    {phone_icon}
                </a>
                <a className="getbutton-icon getbutton-whatsapp-icon-home"
                    target="_blank" href={"https://wa.me/" + data.whatsappbot + "?text=" + whatsapp_message}
                    style={{ 'margin-left': '0px' }}
                >
                    <div className="getbutton-title" >
                        <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                            <div style={{ margin: 'auto' }}> WhatsApp </div>
                        </div>
                    </div>
                    {whatsapp_icon}
                </a>
                <a>
                    <div className="getbutton-icon getbutton-enq-icon" onClick={enquire} >
                        <div className="getbutton-title" >
                            <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                                <div style={{ margin: 'auto' }}> Enquire </div>
                            </div>
                        </div>
                        {enq_button}
                    </div>
                </a>
            </div>
        </div>

        <div className="d-block d-sm-none">

            <div style={{ display: (ms) ? 'block' : 'none', zIndex: '5' }} className="popup-wrap"></div>

            <div style={{ bottom:  '40px' }} className="getbutton-wrap">
                <div onClick={() => setMs(!ms)} className={"main-button" + (ms ? "" : " attention-button")}>
                    {(ms) ? close_button :
                        (
                            <AlternatingIcon ttl={4000}>
                                {/* {msg_logo_black} */}
                                {phone_icon_black}
                                {whatsapp_icon_black}
                                {enq_button_black}
                            </AlternatingIcon>
                        )
                    }
                </div>
                <div style={{ transform: 'translateY(60px)' }}>
                    <a style={{ display: ms ? 'block' : 'none' }} className="getbutton-phone-icon-home" target="_blank" href={"tel://" + data.phone_number} >
                        <div className="getbutton-title" >
                            <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                                <div style={{ margin: 'auto' }}> Phone </div>
                            </div>
                        </div>
                        {phone_icon}
                    </a>
                    <a style={{ display: ms ? 'block' : 'none' }} className="getbutton-whatsapp-icon-home" target="_blank" href={"https://wa.me/" + data.whatsappbot + "?text=" + whatsapp_message} >
                        <div className="getbutton-title" >
                            <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                                <div style={{ margin: 'auto' }}> WhatsApp </div>
                            </div>
                        </div>
                        {whatsapp_icon}
                    </a>

                    <div style={{ display: ms ? 'block' : 'none' }} className="getbutton-enq-icon" onClick={enquire} >
                        <div className="getbutton-title" >
                            <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                                <div style={{ margin: 'auto' }}> Enquire </div>
                            </div>
                        </div>
                        {enq_button}
                    </div>

                </div>

            </div>
        </div>
    </>
    )
}
