
import { fallback_city } from '../contexts/DataContext';
import StorageBucket from './StorageBucket';
import { createClient } from '@prismicio/client';
import { predicate } from '@prismicio/client';
import { filter } from '@prismicio/client';
const apiEndpoint = 'https://homekonnectcms.prismic.io/api/v2';



const PrismicClient = createClient(apiEndpoint);

class ThroughCache {
    constructor(target, ttl) {
        var time = (ttl) ? ttl : 4;
        this.storage = new StorageBucket(time * 60 * 60 * 1000);
        this.target = target;
    }

    call = async (query) => {
        const data = this.storage.load(JSON.stringify(query));
        if (data) {
            this.target(query).then(res => {
                this.storage.store(JSON.stringify(query), (res));
            }
            )
            return data
        }
        else {
            //console.log(query, "Here!!Than")
            const out = await this.target(query);
            this.storage.store(JSON.stringify(query), out);
            //console.log(out, "Out HE")
            return out
        }

    }
}

function rank_props(database, tokens) {
    database = [...new Set(database)];
    var outputs = database.map(p => {
        const out = tokens.map((q) => {
            if (q.length < 0) return false;
            const fullname = p.data.full_name;
            const data = " " + Object.entries(p.data).map(r => r[1]).filter(r => typeof (r) == 'string').join(" ");
            return data.toLowerCase().includes(q.toLowerCase()) ? ((fullname.substr(0, q.length).toLowerCase() == q.toLowerCase()) ? 30 : (data.toLowerCase().includes(" " + q.toLowerCase()) ? 10 : 1)) : 0;
        }
        );

        return {
            data: p,
            score: out.reduce((f, s) => (f + s), 0)
        }
    });
    outputs = outputs.filter(p => p.score != 0);
    outputs = outputs.sort((f, s) => (f.score > s.score) ? -1 : 1);

    return outputs.map(p => p.data);
}


{/** Modified the Entire the Search Core Function  */ }
async function SearchCore(inputs) {
    try {
        // console.log(inputs, "inputs Over Here!!");

        if (inputs.full_name && !inputs.text) inputs.text = inputs.full_name;
        let query = [filter.at("document.type", "properties")];

        // Handling City Filtering
        if (inputs.city && inputs.city.length > 0 && inputs.city[0] !== fallback_city) {
            let city_ids = [];
            const cities = await window.cities;

            inputs.city.forEach(city => {
                const city_id = cities.find(p => city && p.city_name.toLowerCase() === city.toLowerCase());
                if (city_id) city_ids.push(city_id.id);
            });

            if (city_ids.length > 0) query.push(filter.any("my.properties.city", city_ids));
            else return { total_results_size: 0 };
        }

        // Handling Builder Filtering
        if (inputs.builder && inputs.builder.length > 0) {
            let builder_ids = [];
            const builders = await window.builders;

            inputs.builder.forEach(builder => {
                const builder_id = builders.find(p => p.builder_name.toLowerCase() === builder.toLowerCase());
                if (builder_id) builder_ids.push(builder_id.id);
            });

            if (builder_ids.length > 0) query.push(filter.any("my.properties.builder_name", builder_ids));
            else return { total_results_size: 0 };
        }

        // Handling Property Type Filtering
        if (inputs.property_type && inputs.property_type.length > 0) {
            let type_ids = [];
            const property_types = await window.property_type;

            inputs.property_type.forEach(type => {
                const type_id = property_types.find(p => p.property_type.toLowerCase() === type.toLowerCase());
                if (type_id) type_ids.push(type_id.id);
            });

            if (type_ids.length > 0) query.push(filter.any("my.properties.property_type_group.property_type", type_ids));
            else return { total_results_size: 0 };
        }

        // Handling BHK Filtering
        if (inputs.bhk && inputs.bhk.length > 0) {
            query.push(filter.any("my.properties.floor_plans.bhk", inputs.bhk));
        }

        // Handling Amenity Filtering
        if (inputs.amenity && inputs.amenity.length > 0) {
            let amenity_ids = [];
            const amenities = await window.amenities;

            inputs.amenity.forEach(amenity => {
                const amenity_id = amenities.find(p => p.amenity.toLowerCase() === amenity.toLowerCase());
                if (amenity_id) amenity_ids.push(amenity_id.id);
            });

            if (amenity_ids.length > 0) query.push(filter.any("my.properties.amenities.amenities1", amenity_ids));
        }

        // Handling Collection Filtering
        if (inputs.collections && inputs.collections.length > 0) {
            let collection_ids = [];
            const collection = await window.collections;

            inputs.collections.forEach(col => {
                const collection_id = collection.results.find(p => p.data.name.toLowerCase() === col.toLowerCase());
                if (collection_id) collection_ids.push(collection_id.id);
            });

            if (collection_ids.length > 0) query.push(filter.any("my.properties.collections.collection", collection_ids));
        }

        // Handling Zoning Filtering
        if (inputs.zoning && inputs.zoning.length > 0) {
            query.push(filter.any("my.properties.zoning", inputs.zoning));
        }

        // Handling Offer Available
        if (inputs.offer_available) {
            query.push(filter.at("my.properties.offer_available", true));
        }

        // Handling Status Filtering
        if (inputs.status && inputs.status.length > 0) {
            query.push(filter.any("my.properties.status", inputs.status));
        }

        // Handling Featured Filtering
        if (inputs.featured) {
            query.push(filter.at("my.properties.featured", true));
        }

        // Handling Text Search (Full Name or Text)
        // if (inputs.text) {
        //     query.push(filter.fulltext("document", inputs.text));
        // }

        const all_results = [];
        let currentPage = 1;
        let totalResults = 0;
        const pageSize = 100; // Smaller chunks for each API call

        do {
            const response = await PrismicClient.get({
                predicates: query,
                fetch: [
                    "properties.images", "properties.videos", "properties.full_name", "properties.rera_number",
                    "properties.currency", "properties.featured", "properties.price_range_minimum", "properties.price_range_maximum",
                    "properties.city", "properties.status", "properties.unit_size", "properties.offer_available",
                    "properties.offer_validity", "properties.location", "properties.images", "properties.builder_name",
                    "properties.property_type_group", "properties.floor_plans", "properties.alert_text"
                ],
                fetchLinks: [
                    'builders.builder_name', 'city.city_name', 'property_types.property_type', 'property_types.icon'
                ],
                pageSize: pageSize,
                page: currentPage,
            });

            // Aggregate results
            all_results.push(...response.results);
            totalResults = response.total_results_size;
            currentPage++;

        } while (all_results.length < totalResults);

        // Filtering results based on budget post-fetch
        let filtered_results = all_results;
        if (inputs.budget) {
            const { min, max } = inputs.budget;
            filtered_results = filtered_results.filter(property => {
                const minPrice = property.data.price_range_minimum;
                const maxPrice = property.data.price_range_maximum;

                // Exclude properties with null minPrice or maxPrice
                if (minPrice === null || maxPrice === null) {
                    return false;
                }

                // Check if the property falls within the budget range
                return (!min || minPrice >= min) && (!max || maxPrice <= max);
            });
        }
         // Filter based on text input post-fetch
         if (inputs.text) {
            const partialText = inputs.text.toLowerCase();
            filtered_results = filtered_results.filter(property => {
                return property.data.full_name.toLowerCase().includes(partialText);
            });
        }
        // To Check for Validity if offer page is being navigated
        if (inputs.offer_available) {
            filtered_results = filtered_results.filter(property => {
                // Parse offer_validity date string into a Date object (handle potential null values)
                const offerValidityDate = property.data.offer_validity ? new Date(property.data.offer_validity) : null;
                if (offerValidityDate) { // Check if offerValidityDate is not null
                    // Get today's date
                    const today = new Date();

                    // Check if offer validity is greater than or equal to today
                    return offerValidityDate >= today;
                } else {
                    // Offer validity is null, decide how to handle (e.g., exclude or include)
                    return false; // Example: exclude properties with null offer_validity
                }
            });
        }

        return {
            total_results_size: filtered_results.length,
            results: filtered_results,
        };
    } catch (error) {
        //console.log(error, "in SearchCore");
    }
}







export default SearchCore;


const SearchCache = new ThroughCache(SearchCore);

export async function Search(inputs) {
    //console.log(inputs, "inputs")
    return SearchCache.call(inputs);
}

async function citiesCore(inputs) {
    const results = await PrismicClient.get({
        predicates: [filter.at("document.type", "city")],
        pageSize: 100
    });
    return results;
}
const CitiesCache = new ThroughCache(citiesCore, 48);
export async function getCities() {
    return CitiesCache.call("cities/");
}

async function getCollectionsCore() {
    try {


        const results = await PrismicClient.get({

            filters: [
                filter.at("document.type", "collection")
            ],
            pageSize: 100,
            fetchLinks: [
                'builders.builder_name',
                'city.city_name',
                'property_types.property_type',
                'property_types.icon',
                'properties.full_name',
                'properties.rera_number',
                'properties.currency',
                'properties.featured',
                'properties.price_range_minimum',
                'properties.price_range_maximum',
                'properties.city',
                'properties.status',
                'properties.unit_size',
                'properties.offer_available',
                'properties.offer_validity',
                'properties.location',
                'properties.images',
                'properties.builder_name',
                'properties.property_type_group',
                'properties.floor_plans',
                'properties.faq'
            ],
            orderings: {
                field: 'my.collection.order',
            },
        });
        //console.log(results, "results in Get collectionstore")
        return results;


    } catch (error) {
        //console.log(error, "Errpr")
    }
}


const CollectionCache = new ThroughCache(getCollectionsCore, 48);
export async function getCollections() {
    return CollectionCache.call("collections/")
}

async function getBuilderNamesCore(inputs) {
    try {
        const results = await PrismicClient.get({
            filters: [
                filter.at("document.type", "builders")
            ],
            pageSize: 100
        });
        //console.log(results, "results")
        return results; // Return the results array
    } catch (error) {
        console.error('Error fetching builder names:', error);
        throw error;
    }
}
const BuilderCache = new ThroughCache(getBuilderNamesCore, 48);
export async function getBuilderNames() {
    return BuilderCache.call("builders/")
}

async function getPropertyTypesCore(inputs) {
    const results = await PrismicClient.get({
        predicates: [filter.at("document.type", "property_types")],
        pageSize: 100,
        fetch: ['property_types.property_type', 'property_types.icon']
    });

    return results;
}
const PropertyTypeCache = new ThroughCache(getPropertyTypesCore, 48);
export async function getPropertyTypes() {
    return PropertyTypeCache.call("property_types/")
}

async function getAmenitiesCore(inputs) {
    try {


        const results = await PrismicClient.get({
            filters: [filter.at("document.type", "amenities")],
            pageSize: 100,
            fetch: ['amenities.amenity', 'amenities.icon'],
            orderings: {
                field: 'my.amenities.amenity',
            },
        });
        //console.log(results, "getAmenitiesCore")
        return results;
    } catch (error) {
        //console.log(error, "errrr")
    }
}
const AmenityCache = new ThroughCache(getAmenitiesCore, 48);

export async function getAmenities() {
    return AmenityCache.call("amenities/");
}

export async function getFavList(inputs) {
    try {
        // Filter out any null values from inputs
        inputs = inputs.filter(p => p != null);

        // Specify the fields to fetch from related documents
        const fetchLinks = ['builders.builder_name', 'city.city_name', 'property_types.property_type', 'property_types.icon'];

        // Specify the fields to fetch from the properties document
        const fetchItem = [
            "properties.full_name", "properties.rera_number", "properties.currency", "properties.featured",
            "properties.price_range_minimum", "properties.price_range_maximum", "properties.city", "properties.status",
            "properties.unit_size", "properties.offer_available", "properties.offer_validity", "properties.location",
            "properties.images", "properties.builder_name", "properties.property_type_group", "properties.floor_plans", "properties.faq"
        ];

        // Perform the query using the updated Prismic API
        const results = await PrismicClient.get({
            predicates: [
                filter.at("document.type", "properties"),
                filter.in("document.id", inputs)
            ],
            fetch: fetchItem,
            fetchLinks: fetchLinks,
            pageSize: 1000
        });

        return results;

    } catch (error) {
        //console.log(error, " Error in getFavList")
    }

}


async function GetBasicDocsCore(inputs) {
    const results = await PrismicClient.get({
        predicates: [
            filter.at('document.type', 'sitevariables')
        ]
    });

    return results.results[0]?.data;
}
const BasicDocsCache = new ThroughCache(GetBasicDocsCore, 48);
export async function GetBasicDocs() {
    return BasicDocsCache.call("Sitevars/")
}

async function GetSingletonCore(inputs) {
    const results = await PrismicClient.get({
        predicates: [
            filter.at('document.type', 'accreditation'),
            filter.any('my.accreditation.type', [inputs])
        ]
    });

    return results.results[0]?.data;
}
const SingleTonCache = new ThroughCache(GetSingletonCore, 48);

export async function GetSingleton(inputs) {
    return SingleTonCache.call(inputs);
}

async function GetFAQCore(inputs) {
    const results = await PrismicClient.get({
        predicates: [
            filter.at('document.type', 'faq'),
            filter.any('my.faq.type', [inputs])
        ]
    });

    return results.results[0]?.data;
}
const FAQCache = new ThroughCache(GetFAQCore, 48);

export async function GetFAQ(inputs) {
    return FAQCache.call(inputs);
}


export async function GetSiteData(key) {
    const results = await PrismicClient.get({
        predicates: [
            filter.at('document.type', 'sitedata'),
            filter.any('my.sitedata.item', [key.toString()])
        ]
    });

    return results.results[0]?.data.content;
}

export async function GetPageData(key) {
    const results = await PrismicClient.get({
        predicates: [
            filter.at('document.type', 'generic_page'),
            filter.any('my.generic_page.page_link', [key.toString()])
        ]
    });

    return results.results;
}


export async function GetCareers() {
    const results = await PrismicClient.get({
        predicates: [
            filter.at('document.type', 'careers')
        ],
        pageSize: 100
    });

    return results.results;
}

export async function GetPricing() {
    const results = await PrismicClient.get({
        predicates: [
            filter.at('document.type', 'pricing')
        ],
        pageSize: 100
    });

    return results.results;
}



export async function GetBlogDataPrismatic(idx) {
    const results = await PrismicClient.get({
        predicates: [
            filter.at('document.type', 'blogs')
        ],
        orderings: 'my.blogs.date desc',
        pageSize: 1,
        page: idx,
        fetchLinks: ['team_members.name', 'team_members.image_link'],
        fetch: [
            'blogs.title',
            'blogs.date',
            'blogs.contents',
            'blogs.image_link',
            'blogs.preview_paragraph',
            'blogs.author',
            'blogs.tags',
            'blogs.faq'
        ]
    });

    return { data: results.results, pages: results.total_pages };
}
export async function GetLinkFromIdx(idx) {
    const results = await PrismicClient.get({
        predicates: [
            filter.at('document.type', 'blogs'),
            filter.at('my.blogs.uid', idx.toString())
        ],
        orderings: 'my.blogs.uid desc',
        pageSize: 1,
        fetch: ['blogs.link_title']
    });

    return { data: results.results, pages: results.total_pages };
}

export async function GetBlogFromName(name) {
    try {
        const results = await PrismicClient.get({
            predicates: [
                filter.at('document.type', 'blogs'),
                filter.at('my.blogs.link_title', name)
            ],
            orderings: 'my.blogs.uid desc',
            pageSize: 1,
            fetchLinks: [
                'team_members.name',
                'team_members.image_link',
                'team_members.hide'
            ],
            fetch: [
                'blogs.title',
                'blogs.minutes',
                'blogs.date',
                'blogs.contents',
                'blogs.image_link',
                'blogs.preview_paragraph',
                'blogs.author',
                'blogs.tags',
                'blogs.faq'
            ]
        });

        return { data: results.results, pages: results.total_pages };
    } catch (error) {
        //console.log(error, "error in the GetBlogFromName ")
    }

}

export async function GetLatestBlogs(num_entries, page_number = 1) {
    try {
        const results = await PrismicClient.get({
            predicates: [filter.at('document.type', 'blogs')],
            orderings: 'my.blogs.date desc', // Order by date field in descending order
            pageSize: num_entries,
            page: page_number,
            fetch: [
                'blogs.title',
                'blogs.link_title',
                'blogs.date',
                'blogs.image_link',
                'blogs.faq'
            ]
        });

        return results.results;
    } catch (error) {
        console.error('Error fetching latest blogs:', error);
        return []; // Return an empty array in case of error
    }
}



export async function GetBlogTitlesPrismatic(num_entries, page_number) {
    try {
        const results = await PrismicClient.get({
            predicates: [
                filter.at('document.type', 'blogs')
            ],
            orderings: 'my.blogs.date desc',
            pageSize: num_entries,
            page: page_number,
            fetchLinks: [
                'team_members.name',
                'team_members.image_link',
                'team_members.hide'
            ],
            fetch: [
                'blogs.title',
                'blogs.link_title',
                'blogs.minutes',
                'blogs.date',
                'blogs.image_link',
                'blogs.preview_paragraph',
                'blogs.author',
                'blogs.faq'
            ]
        });

        return { data: results.results, pages: results.total_pages };
    } catch (error) {
        //console.log(error, "In GetBlogTitlesPrismatic ")
    }

}



async function PropertyDataCore(query) {
    const nextFilters = [
        "properties.full_name",
        "properties.not_assured_project",
        "properties.rera_number",
        "properties.currency",
        "properties.featured",
        "properties.price_range_minimum",
        "properties.price_range_maximum",
        "properties.city",
        "properties.status",
        "properties.unit_size",
        "properties.offer_available",
        "properties.offer_validity",
        "properties.location",
        "properties.images",
        "properties.builder_name",
        "properties.property_type_group",
        "properties.floor_plans",
        "properties.faq"
    ];

    const results = await PrismicClient.get({
        predicates: [
            filter.at('document.type', 'properties'),
            filter.at('my.properties.city', query.city),
            filter.at('my.properties.full_name', query.name)
        ],
        fetchLinks: [
            ...nextFilters,
            'city.city_name',
            'city.loan_interest_minimum',
            'city.loan_interest_maximum',
            'builders.builder_name',
            'builders.total_projects',
            'builders.ongoing_projects',
            'builders.established_year',
            'builders.logo_link',
            'builders.banner_logo_link',
            'builders.website_link',
            'builders.description',
            'property_types.property_type',
            'property_types.icon',
            'amenities.amenity',
            'amenities.icon'
        ]
    });

    return results.results;
}

const PropertyCache = new ThroughCache(PropertyDataCore)



export async function GetPropertyData(city, name) {
    return PropertyCache.call({
        city: city,
        name: name
    });
}


export async function GetEntriesPrismic(contentType, ordering) {
    const results = await PrismicClient.get({
        predicates: [
            filter.at("document.type", contentType)
        ],
        orderings: ordering,
        pageSize: 100
    });

    if (results) {
        const outputs = results.results.map(p => {
            const data = p.data;
            const uid = p.uid;
            return { ...data, id: uid };
        });
        return outputs;
    } else {
        return null;
    }
}

