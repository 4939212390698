import React, { useContext, useState, useEffect } from "react";
import { db } from "../firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { useCookies } from "react-cookie";
import { getCities, GetBasicDocs, getBuilderNames, getPropertyTypes, getAmenities, getInitData, getCollections } from "../classes/ContentProvider";
import ReactGA from 'react-ga';

const ga_tracker = process.env.REACT_APP_GTAG;
const DataContext = React.createContext();

export const fallback_city = "All cities";
export function useData() {
    return useContext(DataContext);
}

export function calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}

function toRad(Value) {
    return Value * Math.PI / 180;
}



export function DataProvider({ children }) {
    const [cookies, setCookie] = useCookies(['language', 'city', 'fav']);
    const [language, setLanguage] = useState(cookies.language || "EN");
    const [city, set_city] = useState(cookies.city || null);
    ReactGA.initialize(ga_tracker, {
        'cookieDomain': 'auto',
        'debug': false
    });
    useEffect(() => {
        const initializeData = async () => {
            try {
                // Set cookie for language
                setCookie('language', language, { path: "/" });

                // Define helper functions to handle promises
                const fetchCities = async () => {
                    if (!window.cities) {
                        window.cities = getCities().then(results => {
                            const out = results.results.map(p => ({
                                id: p.id, ...p.data
                            })).sort((f, s) => f.city_name.localeCompare(s.city_name));
                            return [{ city_name: fallback_city }, ...out];
                        });
                    }
                    return window.cities;
                };

                const fetchBuilders = async () => {
                    if (!window.builders) {
                        window.builders = getBuilderNames().then(res => {
                            return res.results.map(p => ({ id: p.id, ...p.data }))
                                .sort((f, s) => f.builder_name.localeCompare(s.builder_name));
                        });
                    }
                    return window.builders;
                };

                const fetchPropertyTypes = async () => {
                    if (!window.property_type) {
                        window.property_type = getPropertyTypes().then(res => {
                            return res.results.map(p => ({ id: p.id, ...p.data }))
                                .sort((f, s) => f.property_type.localeCompare(s.property_type));
                        });
                    }
                    return window.property_type;
                };

                const fetchAmenities = async () => {
                    if (!window.amenities) {
                        window.amenities = getAmenities().then(res => {
                            return res.results.map(p => ({ id: p.id, ...p.data }))
                                .sort((f, s) => f.amenity.localeCompare(s.amenity));
                        });
                    }
                    return window.amenities;
                };

                const fetchCollections = async () => {
                    if (!window.collections) {
                        window.collections = getCollections();
                    }
                    return window.collections;
                };

                const fetchSiteData = async () => {
                    if (!window.site_data) {
                        window.site_data = GetBasicDocs().then(res => {
                            const gtag = res.g_tag_id;
                            if (gtag) {
                                const script = document.createElement('script');
                                script.type = 'text/javascript';
                                script.src = `https://www.googletagmanager.com/gtag/js?id=${gtag}`;
                                document.head.appendChild(script);

                                const script2 = document.createElement('script');
                                script2.type = 'text/javascript';
                                script2.text = `
                                    window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());
                                    gtag('config', '${gtag}');
                                    window.gtag = gtag;
                                `;
                                document.head.appendChild(script2);
                            }
                            window.profanity = res.profanity_list;
                            return res;
                        });
                    }
                    return window.site_data;
                };

                // Fetch all data
                await Promise.all([
                    fetchCities(),
                    fetchBuilders(),
                    fetchPropertyTypes(),
                    fetchAmenities(),
                    fetchCollections(),
                    fetchSiteData()
                ]);

                // Call any additional function if needed
                getCity();
            } catch (err) {
                console.error('Error initializing data:', err);
            }
        };

        initializeData();
    }, [language]);


    async function getCity() {

        const fallback = fallback_city;
        const all_cities = await window.cities;
        const idx = all_cities.find(p => (p.city_name == city))

        if (!idx && !window.location_asked) {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const my_lat = position.coords.latitude;
                    const my_lon = position.coords.longitude;

                    const distances = all_cities.map(p => {
                        if (p.city_name == fallback) return Infinity
                        return calcCrow(my_lat, my_lon, p.location.latitude, p.location.longitude);
                    })

                    const out = all_cities[distances.indexOf(Math.min(...distances))];
                    setCity(out.city_name);

                }, (error) => {
                    setCity(fallback);
                    window.location_asked = true;
                });


            } else {
                setCity(fallback);


            }
        }
    }

    function setCity(city) {
        setCookie('city', city, { path: "/", maxAge: 60 * 60 * 24 * 365 * 100 });
        set_city(city);
    }

    function getFavoriteCookie() {
        return cookies.fav;
    }

    async function clearFavoriteData(uid) {
        await setCookie('fav', "[]", { path: "/" });
        if (!uid) return true;
        const docRef = doc(db, "users", uid.toString());
        await setDoc(docRef, { "fav": "[]" }, { merge: true });

        return true;
    }


    function setFavoriteData(uid, fav) {
        const bc = new BroadcastChannel('fav');
        setCookie('fav', fav, { path: "/" });

        if (uid) {

            const docRef = doc(db, "users", uid.toString());
            setDoc(docRef, { "fav": fav.toString() }, { merge: true });
        }

        bc.postMessage(fav);
    }

    function setPhone(uid, phone) {
        const docRef = doc(db, "users", uid.toString());
        return setDoc(docRef, { "phone": phone }, { merge: true });
    }

    function setSubmitted(uid) {
        const docRef = doc(db, "users", uid.toString());
        return setDoc(docRef, { "submission": true }, { merge: true });
    }

    function setEmailData(uid, email) {
        const docRef = doc(db, "users", uid.toString());
        return setDoc(docRef, { "email": email }, { merge: true });
    }

    async function getData(uid) {
        const docRef = doc(db, "users", uid.toString());
        return getDoc(docRef);
    }

    async function getPhone(uid) {
        const docRef = doc(db, "users", uid.toString());
        return getDoc(docRef);
    }

    async function pipeNewUserZoho(user) {
        var iframe = document.createElement("iframe");
        iframe.setAttribute("name", "dummy_iframe");
        iframe.setAttribute("id", "dummy_iframe");
        iframe.style.display = "none";
        document.body.appendChild(iframe);

        var form = document.createElement("form");
        form.style.display = 'none';
        form.setAttribute("method", "post");
        form.setAttribute("target", "dummy_iframe")
        form.setAttribute("action", "https://google.com");

        var referer = document.createElement("input");
        referer.setAttribute("type", "hidden");
        referer.setAttribute("name", "zf_referrer_name");
        form.appendChild(referer);

        var redirect = document.createElement("input");
        redirect.setAttribute("type", "hidden");
        redirect.setAttribute("name", "zf_redirect_url");
        redirect.setAttribute("value", window.location);

        form.appendChild(redirect);

        var gcgad = document.createElement("input");
        gcgad.setAttribute("type", "hidden");
        gcgad.setAttribute("name", "zc_gad");
        form.appendChild(gcgad);

        var SingleLine = document.createElement("input");
        SingleLine.setAttribute("type", "text");
        SingleLine.setAttribute("name", "SingleLine");
        SingleLine.setAttribute("value", user.displayName);
        form.appendChild(SingleLine);

        var PhoneNumber_countrycodeval = document.createElement("input");
        PhoneNumber_countrycodeval.setAttribute("type", "text");
        PhoneNumber_countrycodeval.setAttribute("name", "PhoneNumber_countrycodeval");
        PhoneNumber_countrycodeval.setAttribute("value", user.phoneNumber || user.data.phone || "");
        form.appendChild(PhoneNumber_countrycodeval);

        var PhoneNumber_countrycode = document.createElement("input");
        PhoneNumber_countrycode.setAttribute("type", "text");
        PhoneNumber_countrycode.setAttribute("name", "PhoneNumber_countrycode");
        form.appendChild(PhoneNumber_countrycode);

        var Email = document.createElement("input");
        Email.setAttribute("type", "text");
        Email.setAttribute("name", "Email");
        Email.setAttribute("value", user.email || user.data.email || "")
        form.appendChild(Email);

        document.body.appendChild(form);
        const res = await setSubmitted(user.uid);
        form.submit();
    }

    const value = {
        setPhone,
        setEmailData,
        getData,
        getPhone,
        language,
        setLanguage,
        city,
        setCity,
        setFavoriteData,
        clearFavoriteData,
        getFavoriteCookie,
        pipeNewUserZoho,
        ReactGA
        // setCity
    }

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
}