import React, { useState, useEffect, useRef } from 'react'
import { useAuth } from '../contexts/AuthContext';
import {
    Link,
    useLocation,
} from "react-router-dom";

import { TransparentButton } from './Button';
import Validate from '../classes/validation';
import isEmail from 'validator/lib/isEmail';
import Fade from 'react-reveal/Fade';
import config from 'react-reveal/globals';
import PillBox from './PillBox';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { Form } from "react-bootstrap";
import "../css/property.css";
import { getBeds } from "../classes/Auxe"
config({ ssrFadeout: true });

export function TinyForm(props) {
    const data = props.data;
    const beds = getBeds(data);
    const nameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const messageRef = useRef();
    const { currentUser } = useAuth();
    const formRef = useRef();
    const [error, setError] = useState("");
    const [nameerror, setnameError] = useState("");
    const [mailerror, setmailError] = useState("");
    const [phoneerror, setphoneError] = useState("");

    const [touched, setTouched] = useState([])


    const [loading, setLoading] = useState(false);
    const [interests, setInterests] = useState("");
    const [ph, setPh] = useState("");


    useEffect(() => {

        if (currentUser) {
            nameRef.current.value = currentUser.displayName;
            emailRef.current.value = currentUser.email || (currentUser.data && currentUser.data.email);
            setPh(currentUser.phoneNumber && currentUser.phoneNumber.replace("+", "") || (currentUser.data && currentUser.data.phone || ""));
            if (currentUser.data && currentUser.data.phone) {
                phoneRef.current.value = currentUser.data.phone;
            }

        }
    }, []);

    function change_interest(data, interest) {
        var stuff = interests;
        if (!interest) stuff = stuff.replace(data + ", ", "");
        else stuff = stuff + data + ", "
        setInterests(stuff)
    }

    function validate_form(force) {
        const email = emailRef.current && emailRef.current.value;
        const name = nameRef.current && nameRef.current.value;
        const phone = ph;
        const message = messageRef.current && messageRef.current.value;

        var touch = touched;
        if (email) touch.push("email");
        if (name) touch.push("name");
        if (phone) touch.push("phone");

        if (force) {
            touch.push("email");
            touch.push("name");
            touch.push("phone");

        }
        touch = [...new Set(touch)];
        setTouched(touch);

        setError("");
        setnameError(""); setmailError(""); setphoneError("");
        var valid_total = false;
        var [valid, msg] = Validate(name, 1, 256, /[^0-9a-zA-Z ]/, "Name", true);
        if (!valid) {
            setnameError(msg);
            valid_total = valid_total || true;

        }

        [valid, msg] = Validate(email, 8, 256, /[\s\S]/, "Email", false);
        if (!valid) {
            setmailError(msg);
            valid_total = valid_total || true;

        }

        if (!isEmail(email)) {
            setmailError("Email is not valid")
            valid_total = valid_total || true;

        }

        [valid, msg] = Validate(phone, 9, 13, /[^0-9- ]/, "Phone", true);
        if (!valid) {
            setphoneError("Phone number not valid");
            valid_total = valid_total || true;
        }

        return valid_total;
    }

    function handle_submission() {
        // const neo = ["name", "email", "phone"]
        // setTouched(neo)
        if (validate_form(true) || loading) return;
        setLoading(true);
        if (props.action) props.action(true);
        formRef.current.submit();
    }

    const button_opacity = (loading) ? "0.5" : "1";

    var redir = window.location.toString();
    redir = redir.replace(window.location.hash, "")
    redir = redir + "?submission=success";

    return (<>
        <Fade bottom>
            <div style={{ border: 'none', background: 'white', ...props.style }} className="interested-form">
                <div style={{ padding: '0.5em 1em', textAlign: 'center', background: 'black', color: 'white' }} className='gen-text'>
                    <b>{props.caption}</b>
                </div>

                {
                    (props.close) ? (<div onClick={props.close} className="form-close">
                        <svg width="16" height="16" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.6563 18.2663L0.874023 1.48401M0.874023 18.2663L17.6563 1.48401L0.874023 18.2663Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>


                    </div>) : ""
                }

                <div style={{ padding: '1em', paddingBottom: '0', paddingTop: '0.5em' }}>
                    <Form ref={formRef} action='https://forms.zohopublic.com/homekonnect/form/WebsiteContactUs/formperma/_hbZ2ZMpbsQPSWhtCjiGO5ynCgJjqYqrwRgBGZJggkY/htmlRecords/submit' name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data' onChange={() => validate_form()} className="gen-text">


                        <input type="hidden" name="zf_referrer_name" value="contact us page" />
                        <input type="hidden" name="zf_redirect_url" value={redir} />
                        <input type="hidden" name="zc_gad" value="" />
                        <div style={{ display: 'flex' }}>
                            <Form.Group style={{ marginRight: '10px', marginBottom: 0 }} className="our-group" id="SingleLine">
                                {/* <Form.Label >Name</Form.Label> */}
                                <Form.Control placeholder="Name" ref={nameRef} name="SingleLine" fieldType="1" maxlength="255" type="text" required />
                                <div style={{ fontSize: '0.7em', marginTop: 0 }} className="error-box">
                                    {(touched.includes("name")) ? nameerror : (<></>)}
                                </div>
                            </Form.Group>
                            <Form.Group style={{ marginRight: '10px', marginBottom: 0 }} className="our-group" id="Email">
                                {/* <Form.Label >Email</Form.Label> */}
                                <Form.Control ref={emailRef} placeholder="Email" type="text" maxlength="255" name="Email" fieldType="9" required />
                                <div style={{ fontSize: '0.7em', marginTop: 0 }} className="error-box">
                                    {(touched.includes("email")) ? mailerror : (<></>)}
                                    {/* {mailerror} */}
                                </div>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: 0 }} id="PhoneNumber_countrycode">
                                <PhoneInput
                                    ref={phoneRef}
                                    value={ph}

                                    onChange={(phone) => setPh(phone)}
                                    inputStyle={{ width: '100%', border: "none", borderBottom: "1px solid rgba(0,0,0,0.1)", borderRadius: 0, fontFamily: 'var(--gen-font)', color: 'black', paddingTop: '0.5em', paddingBottom: '0.5em', fontSize: '1.2em' }}
                                    country={'in'}
                                    preserveOrder={['preferredCountries']}
                                    preferredCountries={['in', 'us', 'sg', 'ae', 'gb', 'om', 'kw', 'sa', 'au']}
                                    placeholder="Phone"
                                />
                                <div style={{ fontSize: '0.7em', marginTop: 0 }} className="error-box">
                                    {(touched.includes("phone")) ? phoneerror : (<></>)}
                                    {/* {phoneerror} */}
                                </div>
                                {/* <Form.Control placeholder="Phone" className="gen-text" type="text" compname="PhoneNumber" name="PhoneNumber_countrycode" phoneFormat="1" isCountryCodeEnabled="false" maxlength="20" fieldType="11" id="international_PhoneNumber_countrycode" /> */}
                            </Form.Group>
                        </div>

                        <input type="hidden" name="PhoneNumber_countrycode" value={ph} />

                        <div style={{ display: "none" }}>
                            <Form.Group id="msg">
                                <Form.Label >Message</Form.Label><br></br>
                                <textarea ref={messageRef} value={`
Auto Message
============
`+ (props.extra ? props.extra : "") + `

Interested in ` + data.full_name + ", " + data.city.data.city_name + " for " + interests + " BHK"
                                }
                                    style={{ padding: '0.3em', width: '100%', height: '10em', border: '1px solid rgba(0,0,0,0.15)', borderRadius: '5px' }} className="gen-text area-text" name="MultiLine" maxlength="65535"></textarea>
                            </Form.Group>
                        </div>

                        {/* <div style={{ margin: 0, paddingTop: '0.5em', marginTop: 0 }} className="error-box">
                            {error}
                        </div> */}


                    </Form>
                    <center>
                        <div style={{ width: '200px', marginTop: '20px', marginBottom: "20px" }}>
                            <div onClick={handle_submission} style={{ opacity: button_opacity, width: '100%' }} className="trans-btn-accent">
                                <TransparentButton value={
                                    (<>
                                        <svg style={{ marginRight: '1em' }} width="12" height="12" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.37237 12.5981C10.6365 13.8805 12.166 14.8705 13.8535 15.4987L16.1364 13.6821C16.2041 13.6355 16.2843 13.6105 16.3665 13.6105C16.4487 13.6105 16.5289 13.6355 16.5966 13.6821L20.8355 16.4131C20.9964 16.5099 21.1326 16.6427 21.2334 16.8012C21.3342 16.9596 21.3967 17.1393 21.4161 17.3261C21.4355 17.5128 21.4112 17.7015 21.3451 17.8773C21.279 18.0531 21.173 18.211 21.0353 18.3388L19.0491 20.3008C18.7647 20.5818 18.4151 20.7881 18.0315 20.9011C17.6479 21.0142 17.2424 21.0305 16.851 20.9487C12.9465 20.1423 9.34763 18.2537 6.46572 15.4987C3.64777 12.7164 1.69776 9.17606 0.852241 5.30727C0.768714 4.92192 0.786148 4.52153 0.902851 4.1449C1.01955 3.76827 1.23157 3.42817 1.51835 3.15756L3.57723 1.17134C3.70464 1.04042 3.86001 0.940008 4.03173 0.877638C4.20344 0.815267 4.38703 0.792552 4.56877 0.811191C4.7505 0.82983 4.92567 0.889342 5.08114 0.98527C5.23662 1.0812 5.36838 1.21106 5.46656 1.36512L8.28843 5.54949C8.33711 5.6152 8.36338 5.69481 8.36338 5.77658C8.36338 5.85835 8.33711 5.93795 8.28843 6.00366L6.42938 8.23815C7.07465 9.89188 8.07996 11.3812 9.37237 12.5981V12.5981Z" fill="white" />
                                        </svg>

                                        Get a call</>)} style={{ width: '100%', borderStyle: "1px solid rgba(255, 255, 255, 0.5)", borderColor: "rgba(255, 255, 255, 0.7)", backgroundColor: "transparent", buttonColor: "btn-white" }} />
                            </div>

                        </div>
                        {/* By proceeding, you agree with our <br></br>
                        <Link style={{ color: 'var(--accent)', fontSize: '0.7em' }} className="link-stuff capital-text" target="_blank" to="/terms">Terms of use</Link> */}

                    </center>

                </div >
            </div >
        </Fade>
    </>
    )
}

export default function InterestedForm(props) {
    const data = props.data;
    const beds = getBeds(data);
    const nameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const messageRef = useRef();
    const { currentUser } = useAuth();
    const formRef = useRef();
    const [error, setError] = useState("");
    const [nameerror, setnameError] = useState("");
    const [mailerror, setmailError] = useState("");
    const [phoneerror, setphoneError] = useState("");

    const [loading, setLoading] = useState(false);
    const [interests, setInterests] = useState("");
    const [ph, setPh] = useState("");
    const [touched, setTouched] = useState([])



    useEffect(() => {

        if (currentUser) {
            nameRef.current.value = currentUser.displayName;
            emailRef.current.value = currentUser.email || (currentUser.data && currentUser.data.email);
            setPh(currentUser.phoneNumber && currentUser.phoneNumber.replace("+", "") || (currentUser.data && currentUser.data.phone) || "");
            if (currentUser.data && currentUser.data.phone) {
                phoneRef.current.value = currentUser.data.phone;
            }

        }
    }, []);

    function change_interest(data, interest) {
        var stuff = interests;
        if (!interest) stuff = stuff.replace(data + ", ", "");
        else stuff = stuff + data + ", "
        setInterests(stuff)
    }

    function validate_form(force) {
        const email = emailRef.current && emailRef.current.value;
        const name = nameRef.current && nameRef.current.value;
        const phone = ph;
        const message = messageRef.current && messageRef.current.value;

        var touch = touched;
        if (email) touch.push("email");
        if (name) touch.push("name");
        if (phone) touch.push("phone");

        if (force) {
            touch.push("email");
            touch.push("name");
            touch.push("phone");

        }
        touch = [...new Set(touch)];
        setTouched(touch);

        setError("");
        setnameError(""); setmailError(""); setphoneError("");
        var valid_total = false;
        var [valid, msg] = Validate(name, 1, 256, /[^0-9a-zA-Z ]/, "Name", true);
        if (!valid) {
            setnameError(msg);
            valid_total = valid_total || true;

        }

        [valid, msg] = Validate(email, 8, 256, /[\s\S]/, "Email", false);
        if (!valid) {
            setmailError(msg);
            valid_total = valid_total || true;

        }

        if (!isEmail(email)) {
            setmailError("Email is not valid")
            valid_total = valid_total || true;

        }

        [valid, msg] = Validate(phone, 9, 13, /[^0-9- ]/, "Phone", true);
        if (!valid) {
            setphoneError("Phone number not valid");
            valid_total = valid_total || true;

        }

        return valid_total;
    }

    function handle_submission() {

        if (validate_form(true) || loading) return;
        setLoading(true);
        if (props.action) props.action(true);
        formRef.current.submit();
    }

    const button_opacity = (loading) ? "0.5" : "1";

    var redir = window.location.toString();
    redir = redir.replace(window.location.hash, "")
    redir = redir + "?submission=success";
    return (<>
        <Fade bottom>
            <div style={{ background: 'white', ...props.style }} className="interested-form">

                <div style={{ padding: '0.7em', color: "white", background: 'black' }}>
                    <center>
                        <div style={{ color: "var(--accent)" }} className="capital-text">{props.top ? props.top : "Interested in"}</div>
                        <h5 style={{ marginBottom: "0", marginTop: '0.1em' }} className="our-underline">

                            {data.full_name}

                        </h5>
                        <p style={{ marginTop: '0.2em', fontSize: '17px', marginBottom: '0.0em', opacity: '0.8' }}>
                            {data.location}{(data.city.data.city_name != "") ? "," : ""} {data.city.data.city_name}
                        </p>
                    </center>

                </div>
                {
                    (props.close) ? (<div onClick={props.close} className="form-close">
                        <svg width="16" height="16" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.6563 18.2663L0.874023 1.48401M0.874023 18.2663L17.6563 1.48401L0.874023 18.2663Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>


                    </div>) : ""
                }

                <div style={{ padding: '1em', paddingTop: '0.5em' }}>
                    <Form ref={formRef} action='https://forms.zohopublic.com/homekonnect/form/WebsiteGenericForm/formperma/kGlOMbT8eLwWQ_AW1Rd4gbF5aENND29bWJPAQNGTsr8/htmlRecords/submit' name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data' onChange={() => validate_form()} className="gen-text">
                        {(props.hide_options) ? "" : (<div style={{ paddingBottom: '0.5em', marginRight: '-10px' }}>
                            <center>
                                {beds.map((p, idx) => {
                                    return (<PillBox style={{ marginRight: '0.5em', marginTop: '0.2em' }} onChange={(e) => change_interest(p, e)}>
                                        {p} BHK
                                    </PillBox>)
                                })}
                            </center>
                        </div>)}


                        <input type="hidden" name="zf_referrer_name" value="contact us page" />
                        <input type="hidden" name="zf_redirect_url" value={redir} />
                        <input type="hidden" name="zc_gad" value="" />

                        <Form.Group className="our-group" id="SingleLine">
                            {/* <Form.Label >Name</Form.Label> */}
                            <Form.Control placeholder="Name" ref={nameRef} name="SingleLine" fieldType="1" maxlength="255" type="text" required />
                            <div style={{ fontSize: '0.7em' }} className="error-box">
                                {/* {nameerror} */}
                                {(touched.includes("name")) ? nameerror : (<></>)}

                            </div>
                        </Form.Group>

                        <Form.Group className="our-group" id="Email">
                            {/* <Form.Label >Email</Form.Label> */}
                            <Form.Control ref={emailRef} placeholder="Email" type="text" maxlength="255" name="Email" fieldType="9" required />
                            <div style={{ fontSize: '0.7em' }} className="error-box">
                                {/* {mailerror} */}
                                {(touched.includes("email")) ? mailerror : (<></>)}

                            </div>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '0.5em' }} id="PhoneNumber_countrycode">
                            <PhoneInput
                                ref={phoneRef}
                                value={ph}

                                onChange={(phone) => setPh(phone)}
                                inputStyle={{ width: '100%', border: "none", borderBottom: "1px solid rgba(0,0,0,0.1)", borderRadius: 0, fontFamily: 'var(--gen-font)', color: 'black', paddingTop: '0.5em', paddingBottom: '0.5em', fontSize: '1.2em' }}
                                country={'in'}
                                preserveOrder={['preferredCountries']}
                                preferredCountries={['in', 'us', 'sg', 'ae', 'gb', 'om', 'kw', 'sa', 'au']}
                                placeholder="Phone"
                            />
                            <div style={{ fontSize: '0.7em' }} className="error-box">
                                {/* {phoneerror} */}
                                {(touched.includes("phone")) ? phoneerror : (<></>)}

                            </div>
                            {/* <Form.Control placeholder="Phone" className="gen-text" type="text" compname="PhoneNumber" name="PhoneNumber_countrycode" phoneFormat="1" isCountryCodeEnabled="false" maxlength="20" fieldType="11" id="international_PhoneNumber_countrycode" /> */}
                        </Form.Group>
                        <input type="hidden" name="PhoneNumber_countrycode" value={ph.substr(2, ph.length)} />
                        <input type="hidden" name="PhoneNumber_countrycodeval" value={"+" + ph.substr(0, 2)} />

                        {/* <Form.Group className="our-group" id="PhoneNumber_countrycode">
                        <Form.Label >Phone</Form.Label>
                        <Form.Control placeholder="Phone" ref={phoneRef} className="gen-text" type="text" compname="PhoneNumber" name="PhoneNumber_countrycode" phoneFormat="1" isCountryCodeEnabled="false" maxlength="20" fieldType="11" id="international_PhoneNumber_countrycode" />
                    </Form.Group> */}
                        <div style={{ display: "none" }}>
                            <Form.Group id="msg">
                                <Form.Label >Message</Form.Label><br></br>
                                <textarea ref={messageRef} value={"Auto Message\n---------------\nInterested in " + data.full_name + ", " + data.city.data.city_name + " for " + interests + " BHK\n" + ((props.extra) ? props.extra : "")}
                                    style={{ padding: '0.3em', width: '100%', height: '10em', border: '1px solid rgba(0,0,0,0.15)', borderRadius: '5px' }} className="gen-text area-text" name="MultiLine" maxlength="65535"></textarea>
                            </Form.Group>
                        </div>

                        <div className="error-box">
                            {error}
                        </div>


                    </Form>
                    <center>

                        <div onClick={handle_submission} style={{ opacity: button_opacity, marginTop: '1em', width: '100%' }} className="trans-btn-accent">
                            <TransparentButton value={
                                (<>
                                    <svg style={{ marginRight: '1em' }} width="12" height="12" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.37237 12.5981C10.6365 13.8805 12.166 14.8705 13.8535 15.4987L16.1364 13.6821C16.2041 13.6355 16.2843 13.6105 16.3665 13.6105C16.4487 13.6105 16.5289 13.6355 16.5966 13.6821L20.8355 16.4131C20.9964 16.5099 21.1326 16.6427 21.2334 16.8012C21.3342 16.9596 21.3967 17.1393 21.4161 17.3261C21.4355 17.5128 21.4112 17.7015 21.3451 17.8773C21.279 18.0531 21.173 18.211 21.0353 18.3388L19.0491 20.3008C18.7647 20.5818 18.4151 20.7881 18.0315 20.9011C17.6479 21.0142 17.2424 21.0305 16.851 20.9487C12.9465 20.1423 9.34763 18.2537 6.46572 15.4987C3.64777 12.7164 1.69776 9.17606 0.852241 5.30727C0.768714 4.92192 0.786148 4.52153 0.902851 4.1449C1.01955 3.76827 1.23157 3.42817 1.51835 3.15756L3.57723 1.17134C3.70464 1.04042 3.86001 0.940008 4.03173 0.877638C4.20344 0.815267 4.38703 0.792552 4.56877 0.811191C4.7505 0.82983 4.92567 0.889342 5.08114 0.98527C5.23662 1.0812 5.36838 1.21106 5.46656 1.36512L8.28843 5.54949C8.33711 5.6152 8.36338 5.69481 8.36338 5.77658C8.36338 5.85835 8.33711 5.93795 8.28843 6.00366L6.42938 8.23815C7.07465 9.89188 8.07996 11.3812 9.37237 12.5981V12.5981Z" fill="white" />
                                    </svg>

                                    Get a call</>)} style={{ width: '100%', borderStyle: "1px solid rgba(255, 255, 255, 0.5)", borderColor: "rgba(255, 255, 255, 0.7)", backgroundColor: "transparent", buttonColor: "btn-white" }} />
                        </div>
                        <div style={{ fontSize: '0.9em', marginTop: "0.6em" }}>
                            {/* <hr></hr> */}
                            By proceeding, you agree with
                            <Link style={{ color: 'var(--accent)', fontSize: '0.6em', marginLeft: "5px" }} className="link-stuff capital-text" target="_blank" to="/terms">Terms of use</Link>
                        </div>
                    </center>
                </div >
            </div >
        </Fade>
    </>
    )
}

export function GenericForm(props) {
    const data = props.data;
    const nameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const { currentUser } = useAuth();
    const formRef = useRef();
    const [error, setError] = useState("");
    const [nameerror, setnameError] = useState("");
    const [mailerror, setmailError] = useState("");
    const [phoneerror, setphoneError] = useState("");

    const [loading, setLoading] = useState(false);
    const [interests, setInterests] = useState("");
    const [ph, setPh] = useState("");
    const [touched, setTouched] = useState([])



    useEffect(() => {

        if (currentUser) {
            nameRef.current.value = currentUser.displayName;
            emailRef.current.value = currentUser.email || (currentUser.data && currentUser.data.email);
            setPh(currentUser.phoneNumber && currentUser.phoneNumber.replace("+", "") || (currentUser.data && currentUser.data.phone) || "");
            if (currentUser.data && currentUser.data.phone) {
                phoneRef.current.value = currentUser.data.phone;
            }

        }
    }, []);

    function change_interest(data, interest) {
        var stuff = interests;
        if (!interest) stuff = stuff.replace(data + ", ", "");
        else stuff = stuff + data + ", "
        setInterests(stuff)
    }

    function validate_form(force) {
        const email = emailRef.current && emailRef.current.value;
        const name = nameRef.current && nameRef.current.value;
        const phone = ph;

        var touch = touched;
        if (email) touch.push("email");
        if (name) touch.push("name");
        if (phone) touch.push("phone");

        if (force) {
            touch.push("email");
            touch.push("name");
            touch.push("phone");

        }
        touch = [...new Set(touch)];
        setTouched(touch);

        setError("");
        setnameError(""); setmailError(""); setphoneError("");
        var valid_total = false;
        var [valid, msg] = Validate(name, 1, 256, /[^0-9a-zA-Z ]/, "Name", true);
        if (!valid) {
            setnameError(msg);
            valid_total = valid_total || true;

        }

        [valid, msg] = Validate(email, 8, 256, /[\s\S]/, "Email", false);
        if (!valid) {
            setmailError(msg);
            valid_total = valid_total || true;

        }

        if (!isEmail(email)) {
            setmailError("Email is not valid")
            valid_total = valid_total || true;

        }

        [valid, msg] = Validate(phone, 9, 13, /[^0-9- ]/, "Phone", true);
        if (!valid) {
            setphoneError("Phone number not valid");
            valid_total = valid_total || true;

        }

        return valid_total;
    }

    function handle_submission() {

        if (validate_form(true) || loading) return;
        setLoading(true);
        if (props.action) props.action(true);
        formRef.current.submit();
    }

    const button_opacity = (loading) ? "0.5" : "1";

    var redir = window.location.toString();
    redir = redir.replace(window.location.hash, "")
    redir = redir + "?submission=success";

    return (<>
        <Fade bottom>
            <div style={{ background: 'white', ...props.style }} className="interested-form">

                <div style={{ padding: '0.7em', color: "white", background: 'black' }}>
                    <center>
                        <div style={{ color: "var(--accent)" }} className="capital-text">{props.data.supertitle}</div>
                        <h5 style={{ marginBottom: "0", marginTop: '0.1em' }} className="our-underline">

                            {props.data.title}

                        </h5>
                        <p style={{ marginTop: '0.2em', fontSize: '17px', marginBottom: '0.0em', opacity: '0.8' }}>
                            {props.data.subtitle}
                        </p>
                    </center>

                </div>
                {
                    (props.close) ? (<div onClick={props.close} className="form-close">
                        <svg width="16" height="16" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.6563 18.2663L0.874023 1.48401M0.874023 18.2663L17.6563 1.48401L0.874023 18.2663Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>


                    </div>) : ""
                }

                <div style={{ padding: '1em', paddingTop: '0.5em' }}>
                    <Form ref={formRef} action='https://forms.zohopublic.com/homekonnect/form/GenericForm/formperma/IL7vFHUVA1WRUUWT0dBI-riUsOyMGiPN38irNTDS9Bw/htmlRecords/submit' name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data' onChange={() => validate_form()} className="gen-text">


                        <input type="hidden" name="zf_referrer_name" value="Generic Page" />
                        <input type="hidden" name="zf_redirect_url" value={redir} />
                        <input type="hidden" name="zc_gad" value="" />

                        <Form.Group className="our-group" id="SingleLine">

                            <Form.Control placeholder="Name" ref={nameRef} name="SingleLine" fieldType="1" maxlength="255" type="text" required />
                            <div style={{ fontSize: '0.7em' }} className="error-box">
                                {(touched.includes("name")) ? nameerror : (<></>)}
                            </div>
                        </Form.Group>

                        <Form.Group className="our-group" id="Email">
                            <Form.Control ref={emailRef} placeholder="Email" type="text" maxlength="255" name="Email" fieldType="9" required />
                            <div style={{ fontSize: '0.7em' }} className="error-box">
                                {(touched.includes("email")) ? mailerror : (<></>)}

                            </div>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '0.5em' }} id="PhoneNumber_countrycode">
                            <PhoneInput
                                ref={phoneRef}
                                value={ph}

                                onChange={(phone) => setPh(phone)}
                                inputStyle={{ width: '100%', border: "none", borderBottom: "1px solid rgba(0,0,0,0.1)", borderRadius: 0, fontFamily: 'var(--gen-font)', color: 'black', paddingTop: '0.5em', paddingBottom: '0.5em', fontSize: '1.2em' }}
                                country={'in'}
                                preserveOrder={['preferredCountries']}
                                preferredCountries={['in', 'us', 'sg', 'ae', 'gb', 'om', 'kw', 'sa', 'au']}
                                placeholder="Phone"
                            />
                            <div style={{ fontSize: '0.7em' }} className="error-box">
                                {(touched.includes("phone")) ? phoneerror : (<></>)}
                            </div>
                        </Form.Group>
                        <input type="hidden" name="PhoneNumber_countrycode" value={ph.substr(2, ph.length)} />
                        <input type="hidden" name="PhoneNumber_countrycodeval" value={"+" + ph.substr(0, 2)} />


                        <div className="error-box">
                            {error}
                        </div>


                    </Form>
                    <center>

                        <div onClick={handle_submission} style={{ opacity: button_opacity, marginTop: '1em', width: '100%' }} className="trans-btn-accent">
                            <TransparentButton value={
                                (<>
                                    Submit</>)} style={{ width: '100%', borderStyle: "1px solid rgba(255, 255, 255, 0.5)", borderColor: "rgba(255, 255, 255, 0.7)", backgroundColor: "transparent", buttonColor: "btn-white" }} />
                        </div>
                        <div style={{ fontSize: '0.9em', marginTop: "0.5em" }}>
                            <hr></hr>
                            By proceeding, you agree with our <br></br>
                            <Link style={{ color: 'var(--accent)', fontSize: '0.6em' }} className="link-stuff capital-text" target="_blank" to="/terms">Terms of use</Link>
                        </div>
                    </center>
                </div >
            </div >
        </Fade>
    </>
    )
}



export function GenericFormUpload(props) {
    const data = props.data;
    const nameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();

    const { currentUser } = useAuth();
    const formRef = useRef();
    const [error, setError] = useState("");
    const [nameerror, setnameError] = useState("");
    const [mailerror, setmailError] = useState("");
    const [phoneerror, setphoneError] = useState("");

    const [loading, setLoading] = useState(false);
    const [interests, setInterests] = useState("");
    const [ph, setPh] = useState("");
    const [name, setName] = useState("")
    const [touched, setTouched] = useState([])



    useEffect(() => {

        if (currentUser) {
            nameRef.current.value = currentUser.displayName;
            emailRef.current.value = currentUser.email || (currentUser.data && currentUser.data.email);
            setPh(currentUser.phoneNumber && currentUser.phoneNumber.replace("+", "") || (currentUser.data && currentUser.data.phone) || "");
            if (currentUser.data && currentUser.data.phone) {
                phoneRef.current.value = currentUser.data.phone;
            }

        }
    }, []);

    function change_interest(data, interest) {
        var stuff = interests;
        if (!interest) stuff = stuff.replace(data + ", ", "");
        else stuff = stuff + data + ", "
        setInterests(stuff)
    }

    function validate_form(force) {
        const email = emailRef.current && emailRef.current.value;
        const name = nameRef.current && nameRef.current.value;
        const phone = ph;

        var touch = touched;
        if (email) touch.push("email");
        if (name) touch.push("name");
        if (phone) touch.push("phone");

        if (force) {
            touch.push("email");
            touch.push("name");
            touch.push("phone");

        }
        touch = [...new Set(touch)];
        setTouched(touch);

        setError("");
        setnameError(""); setmailError(""); setphoneError("");
        var valid_total = false;
        var [valid, msg] = Validate(name, 1, 256, /[^0-9a-zA-Z ]/, "Name", true);
        if (!valid) {
            setnameError(msg);
            valid_total = valid_total || true;

        }

        [valid, msg] = Validate(email, 8, 256, /[\s\S]/, "Email", false);
        if (!valid) {
            setmailError(msg);
            valid_total = valid_total || true;

        }

        if (!isEmail(email)) {
            setmailError("Email is not valid")
            valid_total = valid_total || true;

        }

        [valid, msg] = Validate(phone, 9, 13, /[^0-9- ]/, "Phone", true);
        if (!valid) {
            setphoneError("Phone number not valid");
            valid_total = valid_total || true;

        }

        return valid_total;
    }

    function handle_submission() {

        if (validate_form(true) || loading) return;
        setLoading(true);
        if (props.action) props.action(true);
        formRef.current.submit();
    }

    const button_opacity = (loading) ? "0.5" : "1";

    var redir = window.location.toString();
    redir = redir.replace(window.location.hash, "")
    redir = redir + "?submission=success";

    return (<>
        <Fade bottom>
            <div style={{ background: 'white', ...props.style }} className="interested-form">

                <div style={{ padding: '0.7em', color: "white", background: 'black' }}>
                    <center>
                        <div style={{ color: "var(--accent)" }} className="capital-text">
                            Join us
                        </div>
                        <h5 style={{ marginBottom: "0", marginTop: '0.1em' }} className="our-underline">

                            {/* {props.data.title} */}
                            Upload your CV

                        </h5>
                        <p style={{ marginTop: '0.2em', fontSize: '17px', marginBottom: '0.0em', opacity: '0.8' }}>
                            {/* {props.data.subtitle} */}
                            Upload your CV to be considered for future vacancies
                        </p>
                    </center>

                </div>
                {
                    (props.close) ? (<div onClick={props.close} className="form-close">
                        <svg width="16" height="16" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.6563 18.2663L0.874023 1.48401M0.874023 18.2663L17.6563 1.48401L0.874023 18.2663Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>


                    </div>) : ""
                }

                <div style={{ padding: '1em', paddingTop: '0.5em' }}>
                    <Form ref={formRef} action='https://forms.zohopublic.com/homekonnect/form/CareersCVFileUploadFinal/formperma/IwP72GmWtKY2DZNm-mthvAmSQRKB8hJZ0uV75ivc4mM/htmlRecords/submit' name='form' id='form' method='POST' accept-charset='UTF-8' enctype='multipart/form-data' onChange={() => validate_form()} className="gen-text">


                        <input type="hidden" name="zf_referrer_name" value="CV Upload" />
                        <input type="hidden" name="zf_redirect_url" value={redir} />
                        <input type="hidden" name="zc_gad" value="" />

                        <Form.Group className="our-group" id="SingleLine">

                            <Form.Control onChange={(e) => setName(e.target.value)} placeholder="Name" ref={nameRef} fieldType="1" maxlength="255" type="text" required />
                            <div style={{ fontSize: '0.7em' }} className="error-box">
                                {(touched.includes("name")) ? nameerror : (<></>)}
                            </div>
                        </Form.Group>

                        <input type="text" maxlength="255" name="Name_First" value={name} hidden />
                        <input type="text" maxlength="255" name="Name_Last" value={name} hidden />

                        <Form.Group className="our-group" id="Email">
                            <Form.Control ref={emailRef} placeholder="Email" type="text" maxlength="255" name="Email" fieldType="9" required />
                            <div style={{ fontSize: '0.7em' }} className="error-box">
                                {(touched.includes("email")) ? mailerror : (<></>)}

                            </div>
                        </Form.Group>

                        <Form.Group className="our-group" id="SingleLine">

                            <Form.Control placeholder="Location" fieldType="1" name="SingleLine" maxlength="255" type="text" required />

                        </Form.Group>
                        <Form.Group style={{ marginTop: '0.5em' }} id="PhoneNumber_countrycode">
                            <PhoneInput
                                ref={phoneRef}
                                value={ph}

                                onChange={(phone) => setPh(phone)}
                                inputStyle={{ width: '100%', border: "none", borderBottom: "1px solid rgba(0,0,0,0.1)", borderRadius: 0, fontFamily: 'var(--gen-font)', color: 'black', paddingTop: '0.5em', paddingBottom: '0.5em', fontSize: '1.2em' }}
                                country={'in'}
                                preserveOrder={['preferredCountries']}
                                preferredCountries={['in', 'us', 'sg', 'ae', 'gb', 'om', 'kw', 'sa', 'au']}
                                placeholder="Phone"
                            />
                            <div style={{ fontSize: '0.7em' }} className="error-box">
                                {(touched.includes("phone")) ? phoneerror : (<></>)}
                            </div>
                        </Form.Group>
                        <input type="file" name="FileUpload" checktype="c1" />
                        <input type="hidden" name="PhoneNumber_countrycode" value={ph.substr(2, ph.length)} />
                        <input type="hidden" name="PhoneNumber_countrycodeval" value={"+" + ph.substr(0, 2)} />


                        <div className="error-box">
                            {error}
                        </div>


                    </Form>
                    <center>

                        <div onClick={handle_submission} style={{ opacity: button_opacity, marginTop: '1em', width: '100%' }} className="trans-btn-accent">
                            <TransparentButton value={
                                (<>
                                    Submit</>)} style={{ width: '100%', borderStyle: "1px solid rgba(255, 255, 255, 0.5)", borderColor: "rgba(255, 255, 255, 0.7)", backgroundColor: "transparent", buttonColor: "btn-white" }} />
                        </div>
                        <div style={{ fontSize: '0.9em', marginTop: "0.5em" }}>
                            <hr></hr>
                            By proceeding, you agree with our <br></br>
                            <Link style={{ color: 'var(--accent)', fontSize: '0.6em' }} className="link-stuff capital-text" target="_blank" to="/terms">Terms of use</Link>
                        </div>
                    </center>
                </div >
            </div >
        </Fade>
    </>
    )
}

