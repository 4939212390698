import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { CookiesProvider } from 'react-cookie';
import { FormProvider } from './contexts/FormContext';
import { DataProvider } from './contexts/DataContext';
import Admin from './pages/Admin';
import GetButton from './components/GetButton';
import Disclaimer from './components/Disclaimer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css'; // Use minified version

import "./css/theme.css";

// Lazy-loaded components
const Landing = lazy(() => import('./pages/Landing'));
const LogIn = lazy(() => import('./pages/LogIn'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Register = lazy(() => import('./pages/Register'));
const Profile = lazy(() => import('./pages/Profile'));
const Shortlists = lazy(() => import('./pages/Shortlists'));
const LogOut = lazy(() => import('./pages/LogOut'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const Person = lazy(() => import('./pages/Person'));
const Services = lazy(() => import('./pages/Services'));
const BlogPage = lazy(() => import('./pages/BlogPage'));
const BlogItem = lazy(() => import('./pages/BlogFromName'));
const BlogIndividual = lazy(() => import('./pages/BlogIndividual'));
const Property = lazy(() => import('./pages/Property'));
const City = lazy(() => import('./pages/City'));
const Builder = lazy(() => import('./pages/Builder'));
const Collection = lazy(() => import('./pages/Collection'));
const FeaturedFull = lazy(() => import('./pages/FeaturedFull'));
const SearchRedirect = lazy(() => import('./components/SearchRedirect'));
const Search = lazy(() => import('./pages/Search'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms'));
const Pricing = lazy(() => import('./pages/Pricing'));
const Faq = lazy(() => import('./pages/Faq'));
const NriCorner = lazy(() => import('./pages/Nri'));
const Phone = lazy(() => import('./pages/Phone'));
const EMI = lazy(() => import('./pages/Emi'));
const GoldClub = lazy(() => import('./pages/GoldClub'));
const Offers = lazy(() => import('./pages/Offers'));
const Accreditation = lazy(() => import('./pages/Accreditation'));
const Associations = lazy(() => import('./pages/Associations'));
const Press = lazy(() => import('./pages/Press'));
const Awards = lazy(() => import('./pages/Awards'));
const AwardYear = lazy(() => import('./pages/AwardYear'));
const Accnass = lazy(() => import('./pages/Accnass'));
const Awnpress = lazy(() => import('./pages/Awnpress'));
const Guide = lazy(() => import('./pages/Guide'));
const Careers = lazy(() => import('./pages/Careers'));
const Apply = lazy(() => import('./pages/CareersApply'));
const Vastu = lazy(() => import('./pages/VastuTips'));
const Sitemap = lazy(() => import('./pages/Sitemap'));
const GenericPage = lazy(() => import('./pages/GenericPage'));
const NotFound = lazy(() => import('./pages/NotFound'));
const DynamicSitemap = lazy(() => import('./pages/DynamicSiteMap'))

function App() {
  try {
    return (
      <div className="App">
        <Router>
          <CookiesProvider>
            <DataProvider>
              <AuthProvider>
                <FormProvider>
                  <ToastContainer autoClose={3000} closeOnClick />
                  <Disclaimer />
                  <GetButton />

                  <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/login" element={<Suspense fallback={<></>}><LogIn /></Suspense>} />
                    <Route path="/contact" element={<Suspense fallback={<></>}><ContactUs /></Suspense>} />
                    <Route path="/register" element={<Suspense fallback={<></>}><Register /></Suspense>} />
                    <Route path="/profile" element={<Suspense fallback={<></>}><Profile /></Suspense>} />
                    <Route path="/shortlists" element={<Suspense fallback={<></>}><Shortlists /></Suspense>} />
                    <Route path="/logout" element={<Suspense fallback={<></>}><LogOut /></Suspense>} />
                    <Route path="/about" element={<Suspense fallback={<></>}><AboutUs /></Suspense>} />
                    <Route path="/forgot_password" element={<Suspense fallback={<></>}><ForgotPassword /></Suspense>} />
                    <Route path="/about/:name" element={<Suspense fallback={<></>}><Person /></Suspense>} />
                    <Route path="/services" element={<Suspense fallback={<></>}><Services /></Suspense>} />
                    <Route path="/blogs" element={<Suspense fallback={<></>}><BlogPage /></Suspense>} />
                    <Route path="/blogs/:name" element={<Suspense fallback={<></>}><BlogItem /></Suspense>} />
                    <Route path="/blogs/:id/:name" element={<Suspense fallback={<></>}><BlogIndividual /></Suspense>} />
                    <Route path="/property/:city/featured" element={<Suspense fallback={<></>}><FeaturedFull /></Suspense>} />
                    <Route path="/property/:city/:name" element={<Suspense fallback={<></>}><Property /></Suspense>} />
                    <Route path="/property/:city" element={<Suspense fallback={<></>}><City /></Suspense>} />
                    <Route path="/builders/:builder" element={<Suspense fallback={<></>}><Builder /></Suspense>} />
                    <Route path="/collections/:collection" element={<Suspense fallback={<></>}><Collection /></Suspense>} />
                    <Route path="/searchbox/:text" element={<SearchRedirect />} />
                    <Route path="/search/:q" element={<Suspense fallback={<></>}><Search /></Suspense>} />
                    <Route path="/search" element={<Suspense fallback={<></>}><Search /></Suspense>} />
                    <Route path="/privacy" element={<Suspense fallback={<></>}><Privacy /></Suspense>} />
                    <Route path="/terms" element={<Suspense fallback={<></>}><Terms /></Suspense>} />
                    <Route path="/pricing" element={<Suspense fallback={<></>}><Pricing /></Suspense>} />
                    <Route path="/faq" element={<Suspense fallback={<></>}><Faq /></Suspense>} />
                    <Route path="/nri" element={<Suspense fallback={<></>}><NriCorner /></Suspense>} />
                    <Route path="/vastu" element={<Suspense fallback={<></>}><Vastu /></Suspense>} />
                    <Route path="/phone" element={<Suspense fallback={<></>}><Phone /></Suspense>} />
                    <Route path="/admin" element={<Suspense fallback={<></>}><Admin /></Suspense>} />
                    <Route path="/emi" element={<Suspense fallback={<></>}><EMI /></Suspense>} />
                    <Route path="/gold" element={<Suspense fallback={<></>}><GoldClub /></Suspense>} />
                    <Route path="/offers" element={<Suspense fallback={<></>}><Offers /></Suspense>} />
                    <Route path="/accreditations&associations" element={<Suspense fallback={<></>}><Accnass /></Suspense>} />
                    <Route path="/accreditations&associations/associations" element={<Suspense fallback={<></>}><Associations /></Suspense>} />
                    <Route path="/accreditations&associations/accreditation" element={<Suspense fallback={<></>}><Accreditation /></Suspense>} />
                    <Route path="/awards&press" element={<Suspense fallback={<></>}><Awnpress /></Suspense>} />
                    <Route path="/awards&press/press" element={<Suspense fallback={<></>}><Press /></Suspense>} />
                    <Route path="/awards&press/awards" element={<Suspense fallback={<></>}><Awards /></Suspense>} />
                    <Route path="/awards&press/awards/:year" element={<Suspense fallback={<></>}><AwardYear /></Suspense>} />
                    <Route path="/guide" element={<Suspense fallback={<></>}><Guide /></Suspense>} />
                    <Route path="/careers" element={<Suspense fallback={<></>}><Careers /></Suspense>} />
                    <Route path="/careers/:job" element={<Suspense fallback={<></>}><Apply /></Suspense>} />
                    <Route path="/sitemap" element={<Suspense fallback={<></>}><Sitemap /></Suspense>} />
                    <Route path="/page/:link" element={<Suspense fallback={<></>}><GenericPage /></Suspense>} />
                    <Route path="/dynamic-sitemap.xml" element={<Suspense fallback={<></>}><DynamicSitemap /></Suspense>} />
                    <Route path="*" element={<Suspense fallback={<></>}><NotFound /></Suspense>} />
                  </Routes>
                </FormProvider>
              </AuthProvider>
            </DataProvider>
          </CookiesProvider>
        </Router>
      </div>
    );
  } catch (error) {
    console.log(error)
  }

}

export default App;
