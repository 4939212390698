/* global grecaptcha */
import React, { useContext, useState, useEffect } from "react";
import { auth, firebase_obj } from "../firebase";
import { sendEmailVerification, GoogleAuthProvider, getRedirectResult, signInWithRedirect, RecaptchaVerifier, getAuth } from "firebase/auth";
import { useData } from "./DataContext";
import { toast } from 'react-toastify';
// import {loadAuth2, gapi} from 'gapi-script';

const AuthContext = React.createContext();
const google_provider = new GoogleAuthProvider();

// const google_scope = "https://www.googleapis.com/auth/user.phonenumbers.read"
// google_provider.addScope(google_scope);

// const google_client_id = "815800584612-7fe94geer5iu7ldfemfn9jjgi8o79vbl.apps.googleusercontent.com"

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [recaptcha, setRecapcha] = useState();
    const { getData, setFavoriteData, getFavoriteCookie, clearFavoriteData, pipeNewUserZoho } = useData();

    function signupEmailPassword(email, password) {
        return auth.createUserWithEmailAndPassword(email, password);
    }

    function loginEmailPassword(email, password) {
        return auth.signInWithEmailAndPassword(email, password)
    }

    function resetPhoneCaptcha() {
        window.recaptchaVerifier.render().then(function (widgetId) {
            grecaptcha.reset(widgetId);
        })
    }

    function loginPhone(phone) {
        // try{ resetPhoneCaptcha() }
        if (phone[0] != "+") phone = "+" + phone;

        let verifier = new firebase_obj.auth.RecaptchaVerifier('phone-in-button', {
            'size': 'invisible',
            'callback': (response) => {
            }
        })
        if (!recaptcha) {
            setRecapcha(verifier);
            return auth.signInWithPhoneNumber(phone, verifier);

        }

        return auth.signInWithPhoneNumber(phone, recaptcha);
    }


    function linkPhone(phone) {
        if (phone[0] != "+") phone = "+" + phone;

        let verifier = new firebase_obj.auth.RecaptchaVerifier('phone-in-button', {
            'size': 'invisible',
            'callback': (response) => {
            }
        })
        if (!recaptcha) {
            setRecapcha(verifier);
            if (!currentUser) return
            return currentUser.linkWithPhoneNumber(phone, verifier);

        }

        return currentUser.linkWithPhoneNumber(phone, recaptcha);
    }

    function loginGoogle() {
        return signInWithRedirect(auth, google_provider);
    }

    function redirectedResult() {

        return getRedirectResult(auth);
    }

    function logout() {
        return auth.signOut()
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email)
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }

    function setName(name) {
        return currentUser.updateProfile({ displayName: name });
    }

    function sendVerificationEmail() {
        return sendEmailVerification(currentUser);
    }

    function setFavorite(data, task) {
        var fav = getFavorites();

        if (task) {
            if (fav) fav.push(data);
            else fav = [data];
            setFavoriteData(currentUser ? currentUser.uid : null, JSON.stringify(fav));
            if (currentUser) currentUser.data.fav = JSON.stringify(fav);

            toast("Property added to favourites")

        }
        else {
            if (fav) {
                fav = fav.filter(p => (p != data && p != null));
                setFavoriteData(currentUser ? currentUser.uid : null, JSON.stringify(fav));
                if (currentUser) currentUser.data.fav = JSON.stringify(fav);
            }
        }
    }

    async function clearFavorites() {
        await clearFavoriteData(currentUser ? currentUser.uid : null);
        return true;
    }

    function getFavorites() {
        var fav = [];
        try { if (currentUser) fav = JSON.parse(currentUser.data.fav); }
        catch (err) { }
        try { fav = fav.concat(getFavoriteCookie()); }
        catch (err) { }
        return [...new Set(fav)];
    }

    useEffect(() => {
        const fetchUserData = async () => {
            const unsubscribe = auth.onAuthStateChanged(async (user) => {
                if (user) {
                    try {
                        const data = await getData(user.uid);
                        if (data.exists()) {
                            user.data = data.data();
                        } else {
                            user.data = {};
                        }

                        if (!user.data.submission && user.displayName) {
                            pipeNewUserZoho(user);
                        }
                    } catch (error) {
                        console.error('Error fetching user data:', error);
                    }
                }
                setCurrentUser(user);
                setLoading(false);
            });

            // Cleanup subscription on unmount
            return () => unsubscribe();
        };

        fetchUserData();
    }, []);

    const value = {
        linkPhone,
        setFavorite,
        getFavorites,
        clearFavorites,
        currentUser,
        loginEmailPassword,
        signupEmailPassword,
        logout,
        resetPassword,
        updateEmail,
        updatePassword,
        setName,
        sendVerificationEmail,
        loginGoogle,
        redirectedResult,
        loginPhone
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}